import React from 'react';
import './Footer.css';
import logo from "../../assets/images/Logovertical.png"
import { IoLogoInstagram } from 'react-icons/io';
import { IoLogoFacebook, IoLogoLinkedin, IoLogoYoutube, IoLogoTwitter } from 'react-icons/io';
import { IoIosCall, IoIosPin, IoIosMail } from 'react-icons/io';



const Footer1 = () => {
    return (
        <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logo} alt="Zhypility Logo" />
          
          </div>
          <div className="footer-section">
            <h3>Contact Us</h3>
            <ul>
              <li><IoIosCall style={{ fontSize: '32px' }} />  +91 90046 83829</li>
              <li><IoIosMail style={{ fontSize: '32px' }} />  elearning@zhypility.com</li>
              <li className='add' ><IoIosPin style={{ fontSize: '32px' }} />  506, 5th Floor, Primus Business <br></br> Park, Thane, Mumbai 400604</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Trending Solutions</h3>
            <ul>
              <li>Augmented Reality</li>
              <li>Virtual Reality</li>
              <li>Mobile Learning</li>
              <li>Video Based Learning</li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <ul>
              <li>About Us</li>
              <li>Blogs</li>
              <li>Our Partners</li>
              <li>Careers</li>
            </ul>
          </div>


        </div>
        <div className="footer-bottom">
    <div className='innertext'>
      <p>© 2020, Zhypility. All rights reserved.</p>
      <div className="footer-social-icons">
        <div className='icon'>
        <a href=" https://www.instagram.com/zhypilityinfosolutions/" target="_blank" rel="noopener noreferrer">
          <IoLogoInstagram className='sociallogo' style={{ fontSize: '26px', color: "black" }} /></a>
        </div>
        <div className='icon'>
        <a href="https://www.facebook.com/zhypility.infosolutions.7/" target="_blank" rel="noopener noreferrer">
          <IoLogoFacebook className='sociallogo' style={{ fontSize: 'inherit', color: "black" }} />
        </a>
        </div>
        <div className='icon'>
        <a href=" https://www.linkedin.com/company/zhypility-infosolutions" target="_blank" rel="noopener noreferrer">
          <IoLogoLinkedin className='sociallogo' style={{ fontSize: 'inherit', color: "black" }} />
        </a>
        </div>
        <div className='icon'>
        <a href=" https://youtube.com/@zhypilityinfosolutions718?feature=shared" target="_blank" rel="noopener noreferrer">
          <IoLogoYoutube className='sociallogo' style={{ fontSize: 'inherit', color: "black" }} />
        </a>
        </div>
        {/* <div className='icon'>
        <a href="https://twitter.com/zhypilityinfo" target="_blank" rel="noopener noreferrer">
          <IoLogoTwitter className='sociallogo' style={{ fontSize: 'inherit', color: "black" }} />
        </a>

        </div> */}
      </div>
    </div>
  </div>
      </footer>
    );
};

export default Footer1;
