import "./Navbar.css"
import logo from "../../assets/images/MainLogo.png"

const Navbar =() =>{
    
    return <>
    
    <nav class="navbar">
  <div class="navbar-left">
    <img src={logo} alt="Logo" class="navbar-logo"/>
  </div>

  {/* <div class="navbar-right">
    <button class="contact-btn">Contact Us</button>
  </div> */}
</nav>

    
    </>
}

export default Navbar;