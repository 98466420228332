import Navbar from "../components/Navbar/Navbar";
import "./homepage.css";
import ChooseUs from "./chooseus";
import arIcon from "../assets/images/augmented-reality (1).png"
import instructorIcon from "../assets/images/presentation (1).png"
import gameIcon from "../assets/images/games.png"
import vrIcon from "../assets/images/virtual-reality.png"
import mobileIcon from "../assets/images/mobile-app.png"
import person from "../assets/images/3rd Image.png"
import Footer1 from "../components/Footer/Footer1"



const Homepage = () => {
    return <>

        <div className="mainhomepage">
            <div className="navbar1">
                <Navbar></Navbar>
            </div>

            <div className="mainheading">
                <h1 className="mainhomepageh1">Top Class Learning</h1>
                <h2 className="mainhomepageh2">from Everywhere</h2>
                <p>
                    We believe everyone has the capacity to be at their best. <br />Zhypility InfoSolutions is the right place where people
                    develop their own <br /> potential.
                </p>
            </div>
        </div>

        <div className="maincount">
            <div className="maincount1">
                <div className="divs">
                    <h1>150+</h1>
                    <h3>Corporate Videos</h3>
                </div>
                <div className="divs">
                    <h1>1,000+</h1>
                    <h3>WBT</h3>
                </div>
                <div className="divs">
                    <h1>250+</h1>
                    <h3>Simulation Hours</h3>
                </div>
                <div className="divs">
                    <h1>2,400+</h1>
                    <h3>Off The Shelf Courses</h3>
                </div>
            </div>
        </div>

<ChooseUs></ChooseUs>



<div className="learning-solutions">
      <h2>EXPLORE OUR LEARNING SOLUTIONS</h2>
      <p>Choose the most powerful solutions that always be on demand.</p>
      <div className="solutions-grid">
        <div className="solution-card">
          <img src={arIcon} alt="Augmented Reality" />
          <h3>Augmented Reality</h3>
          <p>As we spend more and more time on our smartphones, augmented reality is becoming an important reality.</p>
        </div>
        <div className="solution-card">
          <img src={instructorIcon} alt="Instructor Led Training" />
          <h3>Instructor Led Training</h3>
          <p>Our Innovative Learning Environment (ILE) strategies help to maximize the efficiency of classroom training courses.</p>
        </div>
        <div className="solution-card">
          <img src={gameIcon} alt="Game Based Learning" />
          <h3>Game Based Learning</h3>
          <p>Gamification is the art of integrating game elements into non-game applications in a creative way.</p>
        </div>
        <div className="solution-card highlight different">
          <img src={vrIcon} alt="Virtual Reality" />
          <h3>Virtual Reality</h3>
          <p>Virtual Reality is catching up fast as an effective means of providing a safe and realistic learning.</p>
        </div>
        <div className="solution-card">
          <img src={mobileIcon} alt="Mobile Learning" />
          <h3>Mobile Learning</h3>
          <p>Smartphones & computers allow learners to access learning content wherever and whenever they want.</p>
        </div>
      </div>
    </div>


    <section className="hero-section" >
      <div className="hero-content">
       
        <h1>Zhypility InfoSolutions</h1>
        <div className="pioneer">
        <p>
          As pioneers in Learning, we extend our services to all types of organizations. We are
          committed towards creating impactful learning solutions for various domains. We strive to
          stay one step ahead of the constantly-evolving technologies in the e-learning field. We
          design and develop classroom training content and web-based learning technology using formats
          such as simulations, serious games and gamification, animations, mobile learning, customized
          learning, video learning, bite-sized learnings and so on.
        </p>
        </div>
        {/* <button className="hero-button">About Us</button> */}
      </div>
    </section>

    <div className="hero-section1">
      <div className="hero-container1">
        <div className="hero-profile1">
          <img src={person} alt="Profile" />
        </div>
        <div className="hero-text1">
          <h1>Let’s design</h1>
          <h2>engaging, impactful, and award winning E-Learning solutions</h2>
          <p>Choose the most powerful solutions and always be on demand.</p>
          <div className="hero-subscribe1">
            <p>Never miss a course.</p>
            <div className="subscribe-box1">
              <input type="email" placeholder="Your email" />
              <button className="subscribe-btn">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>


<Footer1></Footer1>
    </>
}

export default Homepage;