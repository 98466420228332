import "./chooseus.css";
import partnership from "../assets/images/ChooseUs/partnership.png"
import medal from "../assets/images/ChooseUs/medal.png"
import presentation from "../assets/images/ChooseUs/presentation.png"

import ebook from "../assets/images/ChooseUs/ebook.png"
const ChooseUs=()=>{

    return <>
    <section class="why-choose-us">
        <div class="container">
            <h1>Why Choose Us</h1>
            <h3>Benefits of E-Learning Solutions</h3>
            <div class="benefits-grid">
                <div class="benefit-item">
                    <div className="partimg">
                    <img src={partnership} alt="Partnership Icon"/>
                    </div>
                    <div>
                    <h4>Partnership with Leaders</h4>
                    <p>We work with the region’s leading companies as our esteemed partners.</p>
                    </div>
                    
                    
                </div>
                <div class="benefit-item">
                <div className="partimg">
                    <img src={ebook} alt="Digital Library Icon"/>
                    </div>
                    <div>
                    <h4>Largest Digital Library</h4>
                    <p>We have the largest digital online library for enhancing your learning.</p>
                    </div>
                </div>
                <div class="benefit-item">
                <div className="partimg">
                    <img src={presentation} alt="Training Icon"/>
                    </div>
                    <div>
                    <h4>Excellent Trainings</h4>
                    <p>We train more professionals all over the country and worldwide.</p>
                    </div>
                </div>
                <div class="benefit-item">
                <div className="partimg">
                    <img src={medal} alt="Award Icon"/>
                    </div>
                    <div>
                    <h4>Award Winning Business</h4>
                    <p>An award-winning business listed in the top 100 SME companies.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
}

export default ChooseUs;